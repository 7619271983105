import { Container } from "@mui/material";
import React from "react";

const SlideChild = (props) => {
  return (
    <>
      <div className="slider-child flex-column ">
        <div className="slider-image fade">
          <img src={props.image} alt="" />
        </div>
        <Container>
          <div data-aos="fade-down" className="slider-content">
            <p className="slider-content-p">
              Improving the quality of underprivileged
            </p>
            <h1 className="slider-content-h1">{props.slogan}</h1>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SlideChild;
