import React from "react";

import { useParams } from "react-router-dom";

import { PageHead } from "components/common";
import { Container } from "@mui/material";

const Program = () => {
  const path = useParams().path;

  return (
    <>
      <div className="services-container">
        <PageHead title={path} />
        <Container>
          <div
            className="services-main-container flex-row w-100 flex-align-center flex-justify-center"
            style={{
              height: "50vh",
              fontSize: "1.5rem",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            <h1>Working In Progress</h1>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Program;

// previous code not in used

// const services = [
//   {
//     image: Service1,

//     description:
//       "Literary Initiatives for the Popularizing and Protecting the Odia Language (Magazine, Literary Meeting, Publication and Digital Library in Rural Area etc.)",
//   },
//   {
//     image: Service2,

//     description:
//       "Rural Transformation Activity (Agriculture, Technology and Social Development etc.)",
//   },
//   {
//     image: Service3,

//     description:
//       "Activities for Promotion of Arts, Culture & Heritage (Popularize through Voluntary Work and Collaboration)",
//   },
//   {
//     image: Service4,

//     description:
//       "Geriatric Care and Support (Geriatric Response Centre, Support Service and Counselling)",
//   },
//   {
//     image: Service5,

//     description:
//       "Protection and Rehabilitation Work for Mentally Retarded or Disable (Disability & Handicap)",
//   },
//   {
//     image: Service6,

//     description:
//       "Women & Child Rights Activity (Against Killing Girl Foetal, Child & Women Trafficking)",
//   },
//   {
//     image: Service7,

//     description:
//       "Youth Empowerment (Sports for Development, Vocationally Trained and, Team Building and Self Confident)",
//   },
//   {
//     image: Service8,

//     description:
//       "Disaster Response, Relief Fund, Rehabilitation and Reconstruction(Charity and Philanthropy)",
//   },
//   {
//     image: Service9,

//     description:
//       "Initiatives for Renewable Energy (Awareness, Research and Green Energy)",
//   },
//   {
//     image: Service10,

//     description:
//       "Peace Building and Conflict Resolution (Advocacy & Awareness and Law & Legal Affairs)",
//   },
//   {
//     image: Service11,

//     description:
//       "Human Rights Activity (Social Integration & Personal Realization of Underprivileged)",
//   },
//   {
//     image: Service12,

//     description:
//       "Enhancing Access to Quality and Affordable Health Services (Development and Promotion)",
//   },
//   {
//     image: Service13,

//     description:
//       "Quality and Affordable Education for All (Focus on Children and Underprivileged Section)",
//   },
//   {
//     image: Service14,

//     description:
//       "Urban Development (Training & Capacity Building of Underprivileged Community)",
//   },
//   {
//     image: Service15,

//     description:
//       "Activities for Environment Protection and Combating Climate Change (Research & Studies)",
//   },
// ];

//{
/* <Container>
          <div className="services-content-main flex-column w-100">
            <div className="services-content-main-heading flex-row-reverse">
              <div className="services-content-main-img">
                <img
                  src="http://yardexpress.in/baligharafoundation/wp-content/uploads/2021/09/228119720_264789402145483_8773662423660402763_n.jpg"
                  alt=""
                />
              </div>
              <div className="services-content-main-title flex-column">
                <h1>We Work On Following Multi-Disciplinary Areas.</h1>
                <p>
                  Our mission is to improve the quality of the underprivileged
                  of the society and to work on the different Social issues.
                </p>
              </div>
            </div>
            <div className="services-programme w-100">
              All our initiatives are designed to provide essential support to
              underserved communities depending on their need. Our aim is to
              improve the quality of the underprivileged of the society and to
              work on the different Social issues.
            </div>
          </div>
        </Container>
        <div className="all-services flex-column">
          {services.map((service, index) => {
            return (
              <div className="service-content-all-services-child " key={index}>
                <Container>
                  <div className="flex-row gap-1 aboutus-services-flex-div">
                    <div className="service-content-all-services-child-img">
                      <img src={service.image} alt="" />
                    </div>

                    <div className="service-content-all-services-child-desc flex-row flex-align-center">
                      {service.description}
                    </div>
                  </div>
                </Container>
              </div>
            );
          })}
        </div> */
//}
