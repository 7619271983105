import React, { useState, useEffect } from "react";
import { Cancel, ArrowLeft, ArrowRight } from "@mui/icons-material";

const ZoomImage = ({ image, fn, indexno }) => {
  const [imageCurrentIndex, setImageCurrentIndex] = useState(
    indexno ? indexno : 0
  );

  useEffect(() => {
    if (imageCurrentIndex < 0) {
      setImageCurrentIndex(image.length - 1);
    } else if (imageCurrentIndex > image.length - 1) {
      setImageCurrentIndex(0);
    }
  }, [imageCurrentIndex, image.length]);

  return (
    <div className="zoomimage-main">
      <div className="zoomimage-main-span" onClick={fn}>
        <Cancel />
      </div>
      <div className="zoomimage-click-btn flex-row  w-100 flex-justify-space-between">
        <span
          onClick={() => {
            setImageCurrentIndex(imageCurrentIndex - 1);
          }}
        >
          <ArrowLeft />
        </span>
        <span
          onClick={() => {
            setImageCurrentIndex(imageCurrentIndex + 1);
          }}
        >
          <ArrowRight />
        </span>
      </div>
      <div className="zoom-all-images ">
        <img src={image[imageCurrentIndex]} alt="zoom" />
      </div>
    </div>
  );
};

export default ZoomImage;
