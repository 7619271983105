import React, { useState } from "react";
import { Button, Container } from "@mui/material";

import {
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Cardimg,
  AdminIcon,
  FeatureIcon1,
  FeatureIcon2,
  FeatureIcon3,
  MissionImage1,
  MissionImage2,
  GalleryImg1,
  GalleryImg2,
  GalleryImg3,
  GalleryImg4,
  GalleryImg5,
  GalleryImg6,
} from "asset";
import { Cards, MissionCard } from "components/core/cards";
import { SlideChild, SlideMain } from "components/core/slide";
import { ZoomImage } from "components/core/imageZoom";
import { NavLink } from "react-router-dom";

const Home = () => {
  const [showZoomImage, setShowZoomImage] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);

  let zoomImage = (index) => {
    if (index === null) {
      setShowZoomImage(false);
    }

    setImageIndex(index);
    setShowZoomImage(true);
  };

  let closefn = () => {
    // console.log("closefn");
    setShowZoomImage(false);
  };

  const slideData = [
    {
      image: Slide1,
    },
    {
      image: Slide2,
    },
    {
      image: Slide3,
    },
    {
      image: Slide4,
    },
    {
      image: Slide5,
    },
  ];
  const homemotto = [
    {
      icons: FeatureIcon1,
      title: "Social Development",
      description:
        "We work towards social development & to improve the society.",
    },
    {
      icons: FeatureIcon2,
      title: "Quality Education",
      description: "Our aim is to provide quality education to all.",
    },
    {
      icons: FeatureIcon3,
      title: "Zero Hunger",
      description: "With the focus on zero hunger and providing food security.",
    },
  ];

  const mission = [
    {
      image: MissionImage1,
      title: "Socially Sustainable and Economically Self-dependent",
      description:
        "To have a Society which is Socially Sustainable and Economically Self-dependent where we would wish to live in always. We are also engaged with different Social entities and Stake holders of Odisha to take different social initiatives and improve the teaching learning practices.",
      goals: "10000",
      flex: "row",
    },
    {
      image: MissionImage2,
      title: "Transforming Society In The Best Way Possible",
      description:
        "• Facilitate meaningful activities for Transforming Life • Promote developmental activities for Empowering Society.",
      goals: "10000",
      flex: "row-reverse",
    },
  ];

  const gallery = [
    GalleryImg1,
    GalleryImg2,
    GalleryImg3,
    GalleryImg4,
    GalleryImg5,
    GalleryImg6,
  ];

  const boardmember = [
    {
      image: AdminIcon,
      name: "Mr. Kailash Chandra Swain",
      designation: "Chairman-cum-Founder",
    },
    {
      image: AdminIcon,
      name: "Dr. Sangram Keshari Swain",
      designation: "Managing Director",
    },
    {
      image: AdminIcon,
      name: "Mrs. Sopnita Swain",
      designation: "Executive Director",
    },
    {
      image: AdminIcon,
      name: "Mr. Ullash Kumar Swain",
      designation: "Director, Administration",
    },
  ];

  return (
    <div className="home-main-container">
      <div className="home-main-container-slider">
        <SlideMain>
          {slideData.map((slide, index) => {
            return (
              <SlideChild
                key={index}
                image={slide.image}
                slogan="Transforming Life Empowering Society"
              />
            );
          })}
        </SlideMain>
      </div>
      <Container>
        <div className="home-motto">
          <div className="home-motto-heading flex-column flex-align-center">
            <p>We Change Your Life & World loveicon</p>
            <h1>Charity With Difference</h1>
          </div>
          <div className="home-motto-components flex-row flex-align-center flex-justify-space-between w-100">
            {homemotto.map((motto, index) => {
              return (
                <Cards
                  key={index}
                  icons={motto.icons}
                  title={motto.title}
                  description={motto.description}
                />
              );
            })}
          </div>
        </div>
      </Container>
      <div className="home-contribution-banner flex-column flex-align-center ">
        <h1 className="home-contribution-banner-title">
          Contribute for the betterment of society
        </h1>
        <NavLink to="/donate">
          <Button className="home-contribution-banner-btn btn-primary">
            Donate Now
          </Button>
        </NavLink>
      </div>
      <div className="home-mission-banner">
        <Container>
          <div className="home-mission-banner-heading w-100">
            <h1 className="home-mission-banner-heading-h1">
              Our Mission & Goals
            </h1>
          </div>

          <div className="home-mission-banner-card flex-column  ">
            {mission.map((mission, index) => {
              return (
                <MissionCard
                  key={index}
                  image={mission.image}
                  title={mission.title}
                  description={mission.description}
                  goals={mission.goals}
                  flex={mission.flex}
                />
              );
            })}
          </div>
        </Container>
      </div>
      <div className="home-stats">
        <div className="home-stat-bg-dark">
          <Container>
            <div className="main-home-stats flex-row flex-align-center flex-justify-space-between">
              <div className="flex-column flex-justify-center">
                <span className="home-stats-no">6200 +</span>
                <span className="home-stats-title">Donation</span>
              </div>
              <div className="flex-column flex-justify-center">
                <span className="home-stats-no">80 +</span>
                <span className="home-stats-title">Fund Raised</span>
              </div>
              <div className="flex-column flex-justify-center">
                <span className="home-stats-no">245 +</span>
                <span className="home-stats-title">Volunteers</span>
              </div>
              <div className="flex-column flex-justify-center">
                <span className="home-stats-no">605 +</span>
                <span className="home-stats-title">Projects</span>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="home-gallery">
        <Container>
          <h1 className="home-gallery-heading w-100"> Our Gallery </h1>
          <div className="home-gallery-content flex-justify-space-between">
            {gallery.map((gallery, index) => {
              return (
                <img
                  src={gallery}
                  alt=""
                  key={index}
                  loading="lazy"
                  className="home-gallery-content-image"
                  onClick={() => {
                    zoomImage(index);
                  }}
                />
              );
            })}
            {showZoomImage && (
              <div>
                <ZoomImage image={gallery} indexno={imageIndex} fn={closefn} />
              </div>
            )}
          </div>
        </Container>
      </div>

      <div className="home-aboutus-banner">
        <Container>
          <div className="home-aboutus-main">
            <h1 className="home-aboutus-title w-100">About Us</h1>
            <div className="home-aboutus-content flex-column ">
              <div className="home-about-us-image">
                <img src={Cardimg} alt="About Us" loading="lazy" />
              </div>
              <div className="home-aboutus-description">
                <h3 className="home-aboutus-h3">About Balighara Foundation</h3>

                <p className="home-aboutus-p">
                  Balighara Foundation is incorporated as a Section 8 Company
                  with a mission to improve the quality of the underprivileged
                  of the society and to work on the different Social issues.In
                  our last 31 years of journey, we have worked as a State level
                  Literary organisation named “Balighara Sahitya Sanskruti
                  Pratisthana” taken various steps and conducted number of
                  activities all-round the year to protect the Odia Language,
                  Literature, Art & Culture. We are also engaged with different
                  Social entities and Stake holders of Odisha to take different
                  social initiatives and improve the teaching learning
                  practices.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="home-member-banner">
        <Container>
          <div className="home-member-main flex-column">
            <h1 className="home-member-title w-100">Board of Directors</h1>

            <div className="home-board-member gap-1">
              {boardmember.map((member, index) => {
                return (
                  <div
                    className="home-board-member-card flex-column flex-align-center flex-justify-center"
                    key={index}
                  >
                    <div className="home-board-member-image">
                      <img src={member.image} alt="" loading="lazy" />
                    </div>
                    <div className="home-board-member-name flex-column flex-align-center">
                      <h3 className="home-board-member-name">{member.name}</h3>
                      <p>{member.designation}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
        <div className="home-become-member">
          <Container>
            <div className="home-become-member-container flex-row w-100">
              <div className="home-become-member-call">
                <h1>Become Support Partner</h1>
                <p>
                  Provide financing support to help individuals build
                  livelihoods
                </p>
              </div>
              <NavLink to="/contact">
                <Button className="home-become-member-btn ">Join Us</Button>
              </NavLink>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Home;
