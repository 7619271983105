import React from "react";
import { Container } from "@mui/material";
import {
  Twitter,
  Instagram,
  LinkedIn,
  Facebook,
  ExitToApp,
} from "@mui/icons-material";

import { PageHead } from "components/common";

const Media = () => {
  return (
    <>
      <main>
        <PageHead title="Media" />

        <Container>
          <div className="media-main-container flex-row gap-1">
            <div className="media-fb-timeline flex-column flex-align-center">
              <div className="tablet">
                <div className="content">
                  <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FBaligharaFoundation&tabs=timeline&width=500&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    title="Facebook"
                    style={{ height: "40rem", width: "500px" }}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="other-media  w-100 gap-1 ">
              <div className="media-heading-h1">
                <h1>Follow our handle and Get updated</h1>
              </div>
              <p className="media-p">
                Get updated with the latest news and events from the Balighara
                Foundation on our social media channels.
              </p>
              <div className="flex-column  flex-justify-center all-media-container">
                <div className="twitter-media flex-row flex-align-center flex-justify-center">
                  <div className="media-icon">
                    <Twitter />
                  </div>

                  <div className="media-follow">
                    <a
                      href="https://twitter.com/balighara_fdn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Our Twitter Profile
                      <ExitToApp />
                    </a>
                  </div>
                </div>
                <div className="instagram-media flex-row flex-align-center flex-justify-center">
                  <div className="media-icon">
                    <Instagram />
                  </div>

                  <div className="media-follow">
                    <a
                      href="https://www.instagram.com/balighara_foundation/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Our Instagram Profile
                      <ExitToApp />
                    </a>
                  </div>
                </div>
                <div className="linkedin-media flex-row flex-align-center flex-justify-center">
                  <div className="media-icon">
                    <LinkedIn />
                  </div>

                  <div className="media-follow">
                    <a
                      href="https://www.linkedin.com/in/balighara-foundation-0240a6201/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Our Linked In Profile
                      <ExitToApp />
                    </a>
                  </div>
                </div>
                <div className="facebook-media flex-row flex-align-center flex-justify-center">
                  <div className="media-icon">
                    <Facebook />
                  </div>

                  <div className="media-follow">
                    <a
                      href="https://www.facebook.com/BaligharaFoundation/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Our Facebook Profile <ExitToApp />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};

export default Media;
