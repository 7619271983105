import React from "react";
import { Route, Routes } from "react-router-dom";

import { Home } from "components/home";
import { Gallery, Media, News } from "components/newsevent";
import { ContactUs } from "components/contactus";
import { Aboutus } from "components/aboutus";
import { Program } from "components/programs";
import { DonatePage } from "components/donatenow";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/gallery" element={<Gallery />} />
      <Route path="/media" element={<Media />} />
      <Route path="/news" element={<News />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/about" element={<Aboutus />} />
      <Route path="/programs" element={<Program />} />
      <Route path="/programs/:path" element={<Program />} />
      <Route path="/donate" element={<DonatePage />} />
    </Routes>
  );
};

export default Routers;
