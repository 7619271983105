import React from "react";

const MissionCard = (props) => {
  return (
    <>
      <div
        className={`missioncards-main-container flex-${props.flex} flex-align-center flex-justify-space-between`}
      >
        <div className="missioncards-image">
          <img src={props.image} alt="" loading="lazy" />
        </div>
        <div className="missioncards-contents-right flex-column ">
          <div className="missioncards-contents w-100 flex-column flex-align-center ">
            <h1 className="missioncards-title">{props.title}</h1>
            <p className="missioncards-description">{props.description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionCard;
