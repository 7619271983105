import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const Cards = (props) => {
  return (
    <>
      <div className="cards-main-container flex-column flex-align-center ">
        <div className="card-icons">
          <img src={props.icons} alt="" />
        </div>
        <div className="card-title">{props.title}</div>
        {/* <div className="card-description">{props.description}</div> */}
        <NavLink to="/services">
          <Button variant="outlined">READ MORE</Button>
        </NavLink>
      </div>
    </>
  );
};

export default Cards;
