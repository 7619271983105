import React from "react";
import { Container, TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { LocationOn, PhoneAndroid, Email, Send } from "@mui/icons-material";

import { PageHead } from "components/common";

import { dataBase } from "configs";

const ContactUs = () => {
  const phone = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  let validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    subject: yup.string().required("Subject is required"),
    phone: yup
      .string()
      .required("Phone is required")
      .matches(phone, "Phone number is invalid"),
    message: yup.string().required("Message is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, props) => {
      // console.log(values);

      //send data to database

      dataBase
        .ref("contacts")
        .push({
          timeStamp: Date.now(),
          name: values.name,
          email: values.email,
          subject: values.subject,
          phone: values.phone,
          message: values.message,
          sentFrom: "Balighara Foundation",
        })
        .then(() => {
          props.resetForm();
        })

        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <>
      <section className="contactus-main-section">
        <PageHead title="Contact Us" />

        <Container>
          <div className="contactus-container-main flex-row w-100">
            <div className="contactus-container-left flex-column">
              <h1 className="contactus-container-left-title">Our Contacts</h1>
              <p className="contactus-container-left-desc">
                Balighara Foundation's Corporate Identification Number is (CIN)
                U85300OR2021NPL035350 and its registration number is 35350.Its
                Email address is baligharafoundation@gmail.com and its
                registered address is C/O KAILASH CH SWAIN, MUNDIDA PANDUA,
                RAHAMA JAGATSINGHPUR ,Jagatsinghpur OR 754140 IN and a Corporate
                office is situated at 830, Sabarsahi Ln, Parida Colony,
                Rasulgarh, Bhubaneswar, Odisha 751010.
              </p>

              <div className="contactus-contacts">
                <div className="contactus-contacts-item flex-row flex-align-center">
                  <div className="contactus-contacts-item-icon">
                    <LocationOn />
                  </div>

                  <div className="contactus-contacts-item-text">
                    <h3>Our Office</h3>
                    <a href="https://www.google.com/maps/place/Balighara+Foundation/@20.2935927,85.8613371,19z/data=!4m12!1m6!3m5!1s0x0:0x7159096c40c917c!2sBalighara+Foundation!8m2!3d20.2937418!4d85.8613509!3m4!1s0x0:0x7159096c40c917c!8m2!3d20.2937418!4d85.8613509">
                      Mundida, Jagatsinghpur, Odisha, India 754140
                    </a>
                  </div>
                </div>
                <div className="contactus-contacts-item flex-row flex-align-center">
                  <div className="contactus-contacts-item-icon">
                    <PhoneAndroid />
                  </div>

                  <div className="contactus-contacts-item-text">
                    <h3>Phone</h3>
                    <a href="tel:9937093949">Support +91- 9937093949</a>
                  </div>
                </div>
                <div className="contactus-contacts-item flex-row flex-align-center">
                  <div className="contactus-contacts-item-icon">
                    <Email />
                  </div>

                  <div className="contactus-contacts-item-text">
                    <h3>Email</h3>
                    <a href="mailto:baligharafoundation@gmail.com">
                      baligharafoundation@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="contactus-container-right flex-column">
              <h1 className="contactus-container-right-title">Get in Touch</h1>
              <form onSubmit={formik.handleSubmit}>
                <div className="contactus-container-right-contactform w-100">
                  <span className="contactus-container-right-contactform-span">
                    <TextField
                      placeholder="Your Name"
                      className="w-100"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.name && formik.errors.name ? true : false
                      }
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                      placeholder="Email"
                      className="w-100"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && formik.errors.email
                          ? true
                          : false
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </span>
                  <span className="contactus-container-right-contactform-span">
                    <TextField
                      placeholder="Phone"
                      className="w-100"
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phone && formik.errors.phone
                          ? true
                          : false
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <TextField
                      placeholder="Subject"
                      className="w-100"
                      id="subject"
                      name="subject"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.subject && formik.errors.subject
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.subject && formik.errors.subject
                      }
                    />
                  </span>

                  <span className="contactus-container-right-contactform-span w-100">
                    <TextField
                      placeholder="Message"
                      multiline
                      rows={4}
                      id=""
                      className="contactus-message-textfield w-100"
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.message && formik.errors.message
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    />
                  </span>
                </div>
                <div className="contactus-container-right-btn">
                  <Button endIcon={<Send />} type="submit">
                    {" "}
                    Send Message
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className="contactus-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d935.5403061224172!2d85.86133710000001!3d20.293592699999998!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7159096c40c917c!2sBalighara%20Foundation!5e0!3m2!1sen!2sin!4v1641548170745!5m2!1sen!2sin"
              style={{ width: "100%", height: "100%", border: "0" }}
              allowFullScreen=""
              title="map"
              loading="lazy"
            ></iframe>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
