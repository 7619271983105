import React from "react";
import { Container } from "@mui/material";

import { PageHead } from "components/common";

const News = () => {
  const newsPdf =
    "https://firebasestorage.googleapis.com/v0/b/balighara-foundation.appspot.com/o/Balighara%20Foundation%20Annual%20News%20Published%202021.pdf?alt=media&token=295da1ca-c5c8-4276-8626-826a5e5ab76a&embeded=true#toolbar=0";
  return (
    <>
      <main>
        <PageHead title="news and events" />
        <Container>
          <div className="news-heading w-100 flex-row  flex-justify-center">
            <h1>
              Our News and Events as published on news paper and in the media.
            </h1>
          </div>
        </Container>

        <Container>
          <div className="news-main-container">
            {/* <iframe
              src={newsPdf}
              frameBorder="0"
              title="news"
              style={{ height: "100vh", width: "100%" }}
            ></iframe> */}

            <embed
              src={newsPdf}
              type="application/pdf"
              height={800}
              width={500}
              className="news-pdf"
            />
          </div>
        </Container>
      </main>
    </>
  );
};

export default News;
