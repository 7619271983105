import './App.css';

import { BrowserRouter } from 'react-router-dom';



import { Navbar } from "components/navbar";

import { Footer } from "components/footer";

import { Routers } from "components/routes";


function App() {

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routers />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
