import React from "react";
import { Container } from "@mui/material";

import { AdminIcon, Cardimg } from "asset";
import { PageHead } from "components/common";

const Aboutus = () => {
  const member = [
    {
      name: "Mr. Kailash Chandra Swain",
      role: "Founder & CEO",
      image: AdminIcon,
    },
    {
      name: "Dr. Sangram Keshari Swain",
      role: "Managing Director",
      image: AdminIcon,
    },
    {
      name: "Mrs. Sopnita Swain",
      role: "Executive Director",
      image: AdminIcon,
    },
    {
      name: "Mr. Ullash Kumar Swain",
      role: "Director,Administration",
      image: AdminIcon,
    },
  ];
  return (
    <section className="aboutus-main-section">
      <PageHead title="About Us" />

      <Container>
        <div className="aboutus-container">
          <div className="aboutus-container-main">
            <div className="aboutus-container-main-left">
              <img src={Cardimg} alt="Balighara Foundation" />
            </div>

            <div className="aboutus-container-main-right flex-column">
              <h1 className="aboutus-container-main-right-title">About us</h1>

              <p className="aboutus-container-main-right-text">
                Balighara Foundation is incorporated as a Section 8 Company with
                a mission to improve the quality of the underprivileged of the
                society and to work on the different Social issues.In our last
                31 years of journey, we have worked as a State level Literary
                organisation named “Balighara Sahitya Sanskruti Pratisthana”
                taken various steps and conducted number of activities all-round
                the year to protect the Odia Language, Literature, Art &
                Culture. We are also engaged with different Social entities and
                Stake holders of Odisha to take different social initiatives and
                improve the teaching learning practices.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className="home-stats">
        <div className="home-stat-bg-dark">
          <Container>
            <div className="main-home-stats flex-row flex-align-center flex-justify-space-between">
              <div className="flex-column flex-justify-center">
                <span className="home-stats-no">6200 +</span>
                <span className="home-stats-title">Donation</span>
              </div>
              <div className="flex-column flex-justify-center">
                <span className="home-stats-no">80 +</span>
                <span className="home-stats-title">Fund Raised</span>
              </div>
              <div className="flex-column flex-justify-center">
                <span className="home-stats-no">245 +</span>
                <span className="home-stats-title">Volunteers</span>
              </div>
              <div className="flex-column flex-justify-center">
                <span className="home-stats-no">605 +</span>
                <span className="home-stats-title">Projects</span>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="aboutus-team">
        <Container>
          <div className="aboutus-team-main flex-row flex-justify-space-between">
            {member.map((member, index) => {
              return (
                <div
                  className="aboutus-team-child flex-column flex-align-center"
                  key={index}
                >
                  <div className="aboutus-team-child-img flex-row flex-justify-center">
                    <img src={member.image} alt={member.name} />
                  </div>
                  <div className="aboutus-team-child-desc flex-column flex-align-center">
                    <h1 className="aboutus-team-name">{member.name}</h1>

                    <p className="aboutus-team-role">{member.role}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Aboutus;
