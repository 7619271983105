import React, { useState, useEffect, useRef } from "react";
import { Button, Container } from "@mui/material";
import { KeyboardArrowLeft, ChevronRight } from "@mui/icons-material";

import { NavLink } from "react-router-dom";

const SlideMain = ({ children }) => {
  const [slidePosition, setSlidePosition] = useState(0);
  // console.log(children.length);
  // console.log(slidePosition);

  const slideRef = useRef(null);

  useEffect(() => {
    let myTimeout = setTimeout(() => {
      slideRef.current?.scrollBy(slideRef.current?.offsetWidth, 0);

      if (slidePosition === children.length - 1) {
        slideRef.current?.scrollTo(0, 0);
      }
      if (
        slideRef.current?.scrollleft >
        slideRef.current?.scrollWidth - slideRef.current?.offsetWidth
      ) {
        slideRef.current?.scrollTo(0, 0);
      }
    }, 2500);
    // console.log(slidePosition === children.length - 1);

    return () => {
      clearTimeout(myTimeout);
    };
  }, [slidePosition, children.length]);

  // console.log(slideRef.current?.getBoundingClientRect());

  return (
    <>
      <div className="slide-main-container flex-column">
        <div
          className="slide-main-container-inner"
          style={{ position: "relative" }}
        >
          <div className="left-right-slide-btn flex-row w-100 flex-justify-space-between">
            <span
              className="left-right-slide-btn-span"
              onClick={() => {
                slideRef.current?.scrollBy(-slideRef.current?.offsetWidth, 0);
                // setSlidePosition(slidePosition - 1);
              }}
            >
              <KeyboardArrowLeft />
            </span>
            <span
              className="left-right-slide-btn-span"
              onClick={() => {
                slideRef.current?.scrollBy(slideRef.current?.offsetWidth, 0);

                // setSlidePosition(slidePosition + 1);
              }}
            >
              <ChevronRight />
            </span>
          </div>
          <div
            className="slide-main-container-children flex-row "
            ref={slideRef}
            onScroll={() => {
              setSlidePosition(
                Math.floor(
                  slideRef.current?.scrollLeft / slideRef.current?.offsetWidth
                )
              );
            }}
          >
            {children}
          </div>
        </div>
        <div className="slider-btn flex-row">
          <Container>
            <NavLink to="/services">
              <Button className="slide-left-btn">HOW WE HELP YOU</Button>
            </NavLink>
            <NavLink to="/donate">
              <Button className="slide-right-btn"> SUPPORT US</Button>
            </NavLink>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SlideMain;
