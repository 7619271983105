import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyC8E4VQ07tKiv6sA4nhq8pZ4GX5E5EA6fw",
    authDomain: "balighara-foundation.firebaseapp.com",
    databaseURL: "https://balighara-foundation-default-rtdb.firebaseio.com",
    projectId: "balighara-foundation",
    storageBucket: "balighara-foundation.appspot.com",
    messagingSenderId: "432434678124",
    appId: "1:432434678124:web:061f8b33cc5ed831364b49",
    measurementId: "G-H20ZR0PBDL"
};


const app = firebase.initializeApp(firebaseConfig);

const dataBase = app.database();




export default app;

export { dataBase };