import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Container } from "@mui/material";
import {
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  LocalPhone,
  LocationOn,
  CheckCircle,
} from "@mui/icons-material";

import { Logo } from "asset";
import { RightNav } from ".";

const hoverableNav = [
  {
    name: "Language and Literature",
    path: "/programs/language",
  },
  {
    name: "Art, Culture and Heritage",
    path: "/programs/art&culture",
  },
  {
    name: "Women and Child Rights",
    path: "/programs/women&child",
  },
  {
    name: "Education, Skill and Consultancy",
    path: "/programs/education",
  },
  {
    name: "Rural Community and Underprivileged",
    path: "/programs/rural community",
  },
  {
    name: "Agriculture, Climate and Environment",
    path: "/programs/agriculture",
  },
  {
    name: "Youth and Human Rights",
    path: "/programs/youth",
  },
  {
    name: "Health and Wellness",
    path: "/programs/health",
  },
  {
    name: "Disaster, Relief and Rehabilitation",
    path: "/programs/disaster",
  },
  {
    name: "Humanity, Peace and Harmony",
    path: "/programs/humanity",
  },
];

const Navbar = () => {
  return (
    <>
      <div className="navbar-main-container">
        <div className="nav-up-contact">
          <Container className="nav-up-contact-container">
            <div className="nav-up-contact-left">
              <h3>Follow Us</h3>
              <ul className="nav-up-contact-left-ul">
                <li>
                  <a
                    href="https://www.facebook.com/BaligharaFoundation/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/balighara_foundation/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/balighara_fdn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/balighara-foundation-0240a6201/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedIn />
                  </a>
                </li>
              </ul>
            </div>
            <div className="nav-up-contact-right">
              <span className="nav-up-contact-right-span">
                <LocalPhone />
                <span>+91 9937093949</span>
              </span>
              <span className="nav-up-contact-right-span">
                <LocationOn />
                <span> </span>
                Mundida, Jagatsinghpur, Odisha, India
              </span>
            </div>
          </Container>
        </div>
        <div className="nav-down-navigation">
          <Container className="nav-down-navigation-container">
            <div className="nav-down-navigation-left">
              <NavLink to="/">
                <img src={Logo} alt="" />
              </NavLink>
            </div>
            <div className="nav-down-navigation-right">
              <ul className="nav-down-navigation-right-ul">
                <li>
                  <NavLink to="/">HOME</NavLink>
                </li>
                <li>
                  <NavLink to="/about">ABOUT</NavLink>
                </li>
                <li className="navbar-hoverable-li">
                  <span>PROGRAMS</span>
                  <div className="navbar-child-hover-div flex-column ">
                    {hoverableNav.map((item, index) => {
                      return (
                        <span className="navbar-child-hover-span" key={index}>
                          <NavLink to={item.path}>{item.name}</NavLink>
                        </span>
                      );
                    })}
                  </div>
                  {/* <NavLink to="/programs">PROGRAMS</NavLink> */}
                </li>

                <li className="navbar-hoverable-li">
                  <span>NEWS & EVENTS</span>
                  <div className="navbar-child-hover-div flex-column ">
                    <span className="navbar-child-hover-span">
                      <NavLink to="/Media">Media</NavLink>
                    </span>
                    <span className="navbar-child-hover-span">
                      <NavLink to="/news">News</NavLink>
                    </span>
                    <span className="navbar-child-hover-span">
                      <NavLink to="/gallery">Gallery</NavLink>
                    </span>
                  </div>
                </li>
                <li>
                  <NavLink to="/contact">CONTACT NOW</NavLink>
                </li>
              </ul>
              <NavLink to="/donate" className="donatenow-navlink">
                <Button className="nav-down-navigation-right-button">
                  <span>
                    <CheckCircle />
                  </span>{" "}
                  Donate Now
                </Button>
              </NavLink>
            </div>
            <div className="responsive-navbar">
              <RightNav />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Navbar;
