import React from "react";
import { Button, Container, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { PageHead } from "components/common";
import { dataBase } from "configs";

const DonatePage = () => {
  const amount = /^[0-9]*$/;
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    amount: yup
      .string()
      .required("Amount is required")
      .matches(amount, "Amount can only be numbers"),
    address: yup.string().required("Address is required"),
    message: yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      amount: "",
      address: "",
      message: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values, props) => {
      // console.log(values);

      dataBase
        .ref("balighara-foundation/donations")
        .push({
          timeStamp: Date.now(),
          name: values.name,
          email: values.email,
          amount: values.amount,
          address: values.address,
          message: values.message,
        })
        .then(() => {
          props.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });
  return (
    <main>
      <PageHead title="CONTRIBUTE TO THE BALIGHARA FOUNDATION" />

      <section className="donate-now-account-details">
        <Container>
          <div className="donate-now-account-details-container">
            <h1 className="account-details-h1">Account Details</h1>
            <hr />

            <div className="donate-now-account-info gap-1 flex-column">
              <span className="flex-row flex-align-center donate-now-account-info-span">
                <h3>Account Number</h3> - <span>39989937675</span>
              </span>
              <span className="flex-row flex-align-center donate-now-account-info-span">
                <h3>Beneficiary Name </h3> - <span>Balighara Foundation</span>
              </span>
              <span className="flex-row flex-align-center donate-now-account-info-span">
                <h3>Bank Name</h3> - <span>State Bank Of India</span>
              </span>
              <span className="flex-row flex-align-center donate-now-account-info-span">
                <h3>Branch Name</h3> -{" "}
                <span> Bhubanesware Industrial Estate </span>
              </span>
              <span className="flex-row flex-align-center donate-now-account-info-span">
                <h3>IFSC</h3> - <span>SBIN0003313</span>
              </span>
            </div>
          </div>
        </Container>
      </section>

      <section className="donate-now-main-section">
        <Container>
          <form onSubmit={formik.handleSubmit}>
            <div className="donatenow-main-section-container flex-column">
              <div className="donatenow-container-text-h1">
                <h1>Make a Donation</h1>
              </div>
              <div className="donation-container flex-column flex-justify-center w-100 gap-1">
                <div className="donatenow-basic-input w-100 flex-row flex-justify-center gap-1">
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter your name"
                    variant="outlined"
                    className="donatenow-basic-input-textfield"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    name="name"
                    error={
                      formik.touched.name && formik.errors.name ? true : false
                    }
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    id="outlined-basic"
                    placeholder="Email"
                    variant="outlined"
                    className="donatenow-basic-input-textfield"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    name="email"
                    error={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="donatenow-basic-input  w-100 flex-row flex-justify-center gap-1">
                  <TextField
                    id="outlined-basic"
                    placeholder="Address"
                    variant="outlined"
                    className="donatenow-basic-input-textfield"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.address}
                    name="address"
                    error={
                      formik.touched.address && formik.errors.address
                        ? true
                        : false
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                  <TextField
                    id="outlined-basic"
                    placeholder="Amount in rupees"
                    variant="outlined"
                    className="donatenow-basic-input-textfield"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.amount}
                    name="amount"
                    error={
                      formik.touched.amount && formik.errors.amount
                        ? true
                        : false
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </div>

                <div className="donatenow-message-box w-100">
                  <TextField
                    placeholder="Message"
                    multiline
                    rows={5}
                    className="donatenow-message-box-field"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    name="message"
                    error={
                      formik.touched.message && formik.errors.message
                        ? true
                        : false
                    }
                    helperText={formik.touched.message && formik.errors.message}
                  />
                </div>
                <div className="donatenow-btn">
                  <Button type="submit">Donate Now</Button>
                </div>
              </div>
            </div>
          </form>
        </Container>
      </section>
    </main>
  );
};

export default DonatePage;
