import React from "react";
import { Logo } from "asset";

import {
  Box,
  Drawer,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import {
  Menu,
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  ExpandMore,
} from "@mui/icons-material";

const RightNav = () => {
  const [state, setState] = React.useState(false);

  const hoverableNav = [
    {
      name: "Language and Literature",
      path: "/programs/language",
    },
    {
      name: "Art, Culture and Heritage",
      path: "/programs/art&culture",
    },
    {
      name: "Women and Child Rights",
      path: "/programs/women&child",
    },
    {
      name: "Education, Skill and Consultancy",
      path: "/programs/education",
    },
    {
      name: "Rural Community and Underprivileged",
      path: "/programs/ruralcommunity",
    },
    {
      name: "Agriculture, Climate and Environment",
      path: "/programs/agriculture",
    },
    {
      name: "Youth and Human Rights",
      path: "/programs/youth",
    },
    {
      name: "Health and Wellness",
      path: "/programs/health",
    },
    {
      name: "Disaster, Relief and Rehabilitation",
      path: "/programs/disaster",
    },
    {
      name: "Humanity, Peace and Harmony",
      path: "/programs/humanity",
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      className=" responsive-navbar-right-nav-list"
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="responsive-nav-logo">
        <img src={Logo} alt="" />
      </div>

      <div className="responsive-nav-navigation flex-column w-100">
        <ul className="responsive-nav-navigation-ul flex-column ">
          <li>
            <NavLink to="/">HOME</NavLink>
          </li>
          <li>
            <NavLink to="/about">ABOUT</NavLink>
          </li>
          <li className="accordion-li">
            <Accordion className="responsive-nav-accodion">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                className="responsive-nav-navigation-ul-li-accordion-summary"
              >
                <h1>PROGRAMS</h1>
              </AccordionSummary>
              <AccordionDetails>
                <div className="responsive-nav-navigation-ul-li-accordion-details-div flex-column">
                  {hoverableNav.map((item, index) => {
                    return (
                      <span
                        className="responsive-nav-navigation-ul-li-accordion-details-div-span"
                        key={index}
                      >
                        <NavLink to={item.path}>{item.name}</NavLink>
                      </span>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          </li>
          <li className="accordion-li">
            <Accordion className="responsive-nav-accodion">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                className="responsive-nav-navigation-ul-li-accordion-summary"
              >
                <h1>NEWS AND EVENTS</h1>
              </AccordionSummary>
              <AccordionDetails>
                <div className="responsive-nav-navigation-ul-li-accordion-details-div flex-column">
                  <span className="responsive-nav-navigation-ul-li-accordion-details-div-span">
                    <NavLink to="/media">Media</NavLink>
                  </span>
                  <span className="responsive-nav-navigation-ul-li-accordion-details-div-span">
                    <NavLink to="/news">News</NavLink>
                  </span>
                  <span className="responsive-nav-navigation-ul-li-accordion-details-div-span">
                    <NavLink to="/gallery">Gallery</NavLink>
                  </span>
                </div>
              </AccordionDetails>
            </Accordion>
          </li>
          <li>
            <NavLink to="/contact">CONTACT NOW</NavLink>
          </li>
        </ul>
      </div>

      <div className="responsive-navbar-social w-100">
        <ul className="responsive-navbar-social-ul flex-row">
          <li>
            <a
              href="https://www.facebook.com/BaligharaFoundation/"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/balighara_fdn"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/balighara-foundation-0240a6201/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/balighara_foundation/"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram />
            </a>
          </li>
        </ul>
      </div>
      <div
        onClick={toggleDrawer(anchor, false)}
        className="responsive-nav-close-now flex-row flex-align-center flex-justify-center "
      >
        Close
      </div>
    </Box>
  );

  return (
    <>
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <Menu />
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default RightNav;
