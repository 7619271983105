import React from "react";
import { ArrowUpward } from "@mui/icons-material";

const HoverToTop = () => {
  return (
    <div
      className="sticky-slide-to-top flex-row flex-justify-center flex-align-center"
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}
    >
      <ArrowUpward />
    </div>
  );
};

export default HoverToTop;
