import React from "react";
import { Button, Container, TextField } from "@mui/material";
import { Twitter, Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";

import { Logo } from "asset";
import { NavLink } from "react-router-dom";
import { HoverToTop } from "components/common";
import { dataBase } from "configs";

const Footer = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup.string().email("Invalid email").required("Email is required"),
    }),
    onSubmit: (values, props) => {
      // console.log(values);

      dataBase
        .ref("balighara-foundation/subscribers")
        .push({
          timeStamp: Date.now(),
          email: values.email,
        })
        .then(() => {
          props.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  let date = new Date();
  let year = date.getFullYear();
  return (
    <section className="footer-main">
      <Container className="footer-container">
        <div className="footer-heading">
          <div className="footer-heading-logo">
            <NavLink to="/">
              <img
                src={Logo}
                alt="BALIGHARA FOUNDATION"
                className="footer-logo"
              />
            </NavLink>
          </div>
          <div className="footer-heading-desc">
            <p>
              Mission to improve the quality of the underprivileged of the
              society and to work on the different Social issues.
            </p>
            <p>
              All donations are tax-exempted as eligible under section 80G of
              the the Income Tax Act, 1961.
            </p>
          </div>
        </div>
        <div className="footer-about">
          <h1>Gallery</h1>

          <div className="footer-navlink">
            <ul>
              <li>
                <NavLink to="/media" className="footer-navlink">
                  Media
                </NavLink>
              </li>
              <li>
                <NavLink to="/news" className="footer-navlink">
                  News
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery" className="footer-navlink">
                  Social Development
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery" className="footer-navlink">
                  Education
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-get-involved">
          <h1>About us</h1>

          <div className="footer-get-involved-nav">
            <ul>
              <li>
                <NavLink to="/about" className="footer-navlink">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to="/services" className="footer-navlink">
                  What We Do
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery" className="footer-navlink">
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" className="footer-navlink">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-social">
          <div className="footer-social-div">
            <div className="footer-social-icons">
              <h1>Socials</h1>
              <ul>
                <li>
                  <a href="https://twitter.com/balighara_fdn">
                    <Twitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/BaligharaFoundation/">
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/balighara_foundation/">
                    <Instagram />{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/balighara-foundation-0240a6201/">
                    <LinkedIn />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-newsletter-main">
              <p className="footer-newsletter-main-p w-100">
                Subscribe to our newsletter
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="footer-newsletter">
                  <TextField
                    className="newsletter-input"
                    placeholder="Enter your e-mail."
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <Button
                    className="footer-newsletter-sub-button"
                    type="submit"
                  >
                    Subscribe
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <hr />
        <div className="footer-copyright">
          <p> &copy; {year} Copyright 2021 by Balighara Foundation.</p>
        </div>
      </Container>
      <HoverToTop />
    </section>
  );
};

export default Footer;
