

import {
    Gallery1, Gallery2,
    Gallery3, Gallery4, Gallery5, Gallery6, Gallery7, Gallery8, Gallery9, Gallery10,
    Gallery11, Gallery12, Gallery13, Gallery14, Gallery15, Gallery16, Gallery17, Gallery18,
    Gallery19, Gallery20, Gallery21, Gallery22, Gallery23, Gallery24, Gallery25, Gallery26,
    Gallery27, Gallery28, Gallery29, Gallery30, Gallery31, Gallery32, Gallery33, Gallery34,
    Gallery35, Gallery36, Gallery37, Gallery38, Gallery39, Gallery40, Gallery41
} from "asset/gallery"


const GalleryData = [
    {

        id: 1,
        type: "Blood Donation",
        image: Gallery1

    },
    {

        id: 2,
        type: "Blood Donation",
        image: Gallery2

    },
    {

        id: 3,
        type: "No Hunger",
        image: Gallery3

    },
    {

        id: 4,
        type: "Women Empowerment",
        image: Gallery4

    },
    {

        id: 5,
        type: "Women Empowerment",
        image: Gallery5

    },
    {

        id: 6,
        type: "Society Development",
        image: Gallery6

    },
    {

        id: 7,
        type: "Blood Donation",
        image: Gallery7

    },
    {

        id: 8,
        type: "Blood Donation",
        image: Gallery8

    },
    {

        id: 9,
        type: "Blood Donation",
        image: Gallery9

    },
    {

        id: 10,
        type: "Blood Donation",
        image: Gallery10

    },
    {

        id: 11,
        type: "Covid",
        image: Gallery11

    },
    {

        id: 12,
        type: "Covid",
        image: Gallery12

    },
    {

        id: 13,
        type: "Covid",
        image: Gallery13

    },
    {

        id: 14,
        type: "Covid",
        image: Gallery14

    },
    {

        id: 15,
        type: "Covid",
        image: Gallery15

    },
    {

        id: 16,
        type: "Covid",
        image: Gallery16

    },
    {

        id: 17,
        type: "Education",
        image: Gallery17

    },
    {

        id: 18,
        type: "Education",
        image: Gallery18

    },
    {

        id: 19,
        type: "Education",
        image: Gallery19

    },
    {

        id: 20,
        type: "Education",
        image: Gallery20

    },
    {

        id: 21,
        type: "Education",
        image: Gallery21

    },
    {

        id: 22,
        type: "No Hunger",
        image: Gallery22

    },
    {

        id: 23,
        type: "No Hunger",
        image: Gallery23

    },
    {

        id: 24,
        type: "No Hunger",
        image: Gallery24

    },
    {

        id: 25,
        type: "No Hunger",
        image: Gallery25

    },
    {

        id: 26,
        type: "No Hunger",
        image: Gallery26

    },
    {

        id: 27,
        type: "No Hunger",
        image: Gallery27

    },
    {

        id: 28,
        type: "Media",
        image: Gallery28

    },
    {

        id: 29,
        type: "Media",
        image: Gallery29

    },
    {

        id: 30,
        type: "Media",
        image: Gallery30

    },
    {

        id: 31,
        type: "Media",
        image: Gallery31

    },
    {

        id: 32,
        type: "Society Development",
        image: Gallery32

    },
    {

        id: 33,
        type: "Society Development",
        image: Gallery33

    },
    {

        id: 34,
        type: "Society Development",
        image: Gallery34

    },
    {

        id: 35,
        type: "Society Development",
        image: Gallery35

    },
    {

        id: 36,
        type: "Society Development",
        image: Gallery36

    },
    {

        id: 37,
        type: "Society Development",
        image: Gallery37

    },
    {

        id: 38,
        type: "Women Empowerment",
        image: Gallery38

    },
    {

        id: 39,
        type: "Society Development",
        image: Gallery39

    },
    {

        id: 40,
        type: "Women Empowerment",
        image: Gallery40

    },
    {

        id: 41,
        type: "Women Empowerment",
        image: Gallery41

    },

]


export default GalleryData