import React, { useState } from "react";
import { Container } from "@mui/material";

import { ZoomImage } from "components/core/imageZoom";
import { GalleryData } from ".";
import { PageHead } from "components/common";

const Gallery = () => {
  const [showZoomImage, setShowZoomImage] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);
  const [galleryImages, setGalleryImages] = useState(GalleryData);
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0);

  let closefn = () => {
    // console.log("closefn");
    setShowZoomImage(false);
  };

  const filterImage = (type) => {
    if (type === "all") {
      setGalleryImages(GalleryData);
    } else {
      setGalleryImages(
        GalleryData.filter((item) => {
          return item.type === type;
        })
      );
    }
  };
  let zoomImageArry = galleryImages.map((imageData, index) => {
    return imageData.image;
  });

  const filterItems = [
    "all",
    "Blood Donation",
    "Covid",
    "Education",
    "No Hunger",
    "Media",
    "Society Development",
    "Women Empowerment",
  ];

  return (
    <>
      <section className="gallery-main-section">
        <PageHead title="Gallery" />

        <div className="gallery-content-main">
          <Container>
            <div className="gallery-content">
              <div className="gallery-content-nav-bar w-100">
                <ul id="ul" className="gallery-contact-nav-bar-ul flex-row  ">
                  {filterItems.map((item, index) => {
                    return (
                      <li
                        className={`gallery-contact-nav-bar-li ${
                          index === currentFilterIndex ? "active-filter" : ""
                        }`}
                        onClick={(e) => {
                          filterImage(item);
                          setCurrentFilterIndex(index);
                          // console.log(index);
                        }}
                        key={index}
                      >
                        <span>{item}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="gallery-images-container gap-1">
                {galleryImages.map((value, index) => {
                  return (
                    <div className="gallery-image-content" key={value.id}>
                      <img
                        src={value.image}
                        alt=""
                        loading="lazy"
                        className="gallery-image-content-img"
                        onClick={() => {
                          // zoomImage(index);
                          setImageIndex(index);
                          setShowZoomImage(true);
                        }}
                      />
                    </div>
                  );
                })}
                {showZoomImage && (
                  <ZoomImage
                    image={zoomImageArry}
                    indexno={imageIndex}
                    fn={closefn}
                  />
                )}
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Gallery;
